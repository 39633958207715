<template>
    <select v-model="val"  class="form-control">
            <option v-for="opcion in item.aiepi_opcion_item" :key="opcion.id" :value="opcion.valor">
                {{opcion.valor}}
            </option>
    </select>
</template>
<script>
export default {
    props: ['item','value'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    }
}
</script>


