import Service from "./Service";
const baseurl = '/api/historia';

export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    showByIdUser(id) {
        return Service.get(`${baseurl}/show-by-idUser/${id}`);
    },
    updateCheckpoint(id, checkpoint) {
        return Service.put(`${baseurl}/update/checkpoint`, { id: id, checkpoint: checkpoint });
    },
    showUnfinished(id_usuario) {
        return Service.get(`${baseurl}/show-unfinished/${id_usuario}`);
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    },
    close(data) {
        return Service.post(`${baseurl}/close`, data);
    },
    cerrarHistoria(data) {
        return Service.post(`${baseurl}/cerrar`, data);
    }
}