<template>
   <main>
       <div class="row pt-2">
           <div class="col-lg-4 col-md-4 col-sm-4" v-for="item in secciones" :key="item.id">
               <seccion :seccion="item" @selected="onSeccionSelected($event)"></seccion>
           </div>
       </div>
       <modalItems :items="items" :diagnosticos="diagnosticos" :multiple_dx="multiple" :idHistoria="idHistoria" :idSeccion="idSeccion"  ref="modalItems"></modalItems>
   </main>
</template>
<script>
import seccionAiepiService from '../../../../../services/seccionAiepiService';
import seccion from "./seccion";
import modalItems from "./modalItems";
import itemsAiepiService from '../../../../../services/itemsAiepiService';
 
export default {
    props: ['idHistoria','finalizada','idUsuario', 'usuario'],
    components:{seccion,modalItems},
    data(){
        return{
            idSeccion:'',
            idHistoriaAiepi:'',
            secciones:{},
            items:[],
            diagnosticos: [],
            multiple:'',
        }
    },
    methods:{
        async cargarSecciones(){
            const response = await seccionAiepiService.index({
              fecha_nacimiento : this.usuario.fecha_nacimiento
            });
            this.secciones = response.data;
        },
        async cargarItemsSeccion(id){
            const response =await itemsAiepiService.showBySec(id);
            this.items =response.data;
        },
        async onSeccionSelected(seccion){
            await this.cargarItemsSeccion(seccion.id);
            this.diagnosticos=seccion.aiepi_diagnostico;
            this.multiple=seccion.multiple_diagnostico;
            this.idSeccion=seccion.id;
            this.$nextTick(() => {this.$refs.modalItems.open();});
        }
    },
    created(){
        this.cargarSecciones();
        
    }
}
</script>
