<template>
    <input v-model="val"  type="number" class="form-control">
</template>
<script>
export default {
    props: ['item','value'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    }
}
</script>


