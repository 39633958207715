<template>
    <main>
        <button class="btn btn-success shadow-sm" @click="$emit('selected',seccion)"><i class="fa fa-check fa-fw"></i></button>
        <span class="ml-2">{{seccion.nombre}}</span>
    </main>
</template>
<script>
export default {
    props: ['seccion'],
}
</script>

