<template>
    <main>
        <div class="modal fade" id="modalitemsSeccion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Items</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-9 col-md-12 col-sm-12" v-if="control.items.length">
                            <div v-for="item in $v.control.items.$each.$iter" :key="item.id" class="form-group">
                                <label class="form-label"> {{item.$model.nombre}}</label>
                                <texto
                                    v-if="item.$model.tipo_aiepi.codigo === 'TEXT'"
                                    v-model="item.$model.valor" :class="{'is-invalid':item.valor.$error && item.valor.$dirty}"
                                ></texto>
                                <numerico
                                    v-if="item.$model.tipo_aiepi.codigo === 'NUM'"
                                    v-model="item.$model.valor"  :class="{'is-invalid':item.valor.$error && item.valor.$dirty}"
                                ></numerico>
                                <opciones
                                    :item="item.$model"
                                    v-if="item.$model.tipo_aiepi.codigo === 'OPC'"
                                    v-model="item.$model.valor"  :class="{'is-invalid':item.valor.$error && item.valor.$dirty}"
                                ></opciones>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12  " v-if="multiple_dx==='S'" >
                            <label class="form-label">Diagnosticos</label>
                            <ul class="list-group">
                                <li class="list-group-item" v-for="diag in control.diagnosticos" :key="diag.id">
                                    <input class="form-check-input mx-2" :value="diag.id" type="checkbox" v-model="diag.selected"  :id="diag.id">
                                    <label class="form-check-label mx-4" for="defaultCheck1">
                                        {{diag.diagnostico}}
                                    </label>
                                </li>   
                            </ul>
                            <span class="text-danger" v-if="!$v.control.diagnosticos.required">
                                debe seleccionar por lo menos 1 diagnostico
                            </span>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12  px-2" v-else>
                            <label class="form-label">Diagnosticos</label>
                            <ul class="list-group">
                                <li class="list-group-item" v-for="diag in diagnosticos" :key="diag.id">
                                    <input  class="form-check-input mx-2" type="radio" v-model="control.diagnosticos" :id="diag.id" :value="diag.id"  >
                                    <label class="form-check-label mx-4" for="exampleRadios1">
                                        {{diag.diagnostico}}
                                    </label>
                                </li>   
                            </ul>
                            <span class="text-danger" v-if="!$v.control.diagnosticos.required">
                                debe seleccionar 1 diagnostico
                            </span>
                        </div>
                    </div>
                    
                </div>

                <div class="modal-footer">
                    <button class="btn btn-success" @click="guardar()">Guardar</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import historiaAiepiSeccionService from "../../../../../services/historiaAiepiSeccionService";
import $ from 'jquery';
import Swal from "sweetalert2";
import {isEmpty} from 'lodash';
import {required} from "vuelidate/lib/validators";

export default {
    props: ['items','diagnosticos','multiple_dx','idHistoria','idSeccion'],
    components : {texto,numerico,opciones},
    data() {
       return{
           id_historia_aiepi:'',
           id_seccion_historia:'',
            control:{
                id_seccion:'',
                id_historia:'',
                id_usuario:'',
                diagnosticos: [],
                items: [],
            },
       }
    },
    validations (){
        let diagnosticos = {};
        if(this.multiple_dx === 'S'){
            diagnosticos = {
                required : () => {
                    if(!Array.isArray(this.control.diagnosticos)) return false;
                    const seleccionado = this.control.diagnosticos.filter(x => x.selected)
                    return Boolean(seleccionado.length);
                }
            }
        }else{
            diagnosticos  = {required};
        }

        return {
                control:{
                    items : {
                        required,
                        $each : {
                            valor : {required}
                        }
                    },
                    diagnosticos : diagnosticos
                },
        }
    },
    methods:{
        async open(){

            this.id_historia_aiepi='';

            const response = await historiaAiepiSeccionService.showByHc(this.idHistoria,this.idSeccion);
            
            if(!isEmpty(response.data)){
                this.id_historia_aiepi=response.data.id;
            }

            this.control.items=this.items.map(x=>{
                
                let valor = '';

                if(!isEmpty(response.data)){
                    const respuesta_item =  response.data.respuestas.find(res => parseInt(res.id_item) === parseInt(x.id));
                    valor = respuesta_item !== undefined ? respuesta_item.respuesta:'';
                }

                return {
                    ...x,
                    valor:valor,
                }

            })
            
            if(this.multiple_dx==='S'){
                this.control.diagnosticos=this.diagnosticos.map(x=>{
                    let selected=false;
                    if(!isEmpty(response.data)){
                        let diagnostico=response.data.diagnosticos.find(dx=>dx.id_diagnostico===x.id);
                        selected=diagnostico!==undefined;
                    }
                    return {
                        ...x,
                        selected:selected,
                    }
                })
                
            }else{
                let selected='';
                
                if(!isEmpty(response.data)){
                    let diagnostico = response.data.diagnosticos.pop();
                    selected=diagnostico.id_diagnostico;
                }

                this.control.diagnosticos=selected;
            }
            
            $('#modalitemsSeccion').modal('show');
        },
        onChange(event) {
            let texto = event.target.value;
            this.control.diagnosticos.push(texto);
        },
        async guardar(){
            this.$v.$touch();
            
            if(this.$v.$invalid){
                return
            }
            let historia_aiepi={

                id_historia:this.idHistoria,
                id_seccion:this.idSeccion,
                dx_historia: this.multiple_dx === 'S' ? this.control.diagnosticos.filter(x=>x.selected) : [{'id':this.control.diagnosticos}],
                respuesta_items:this.control.items,
            };
            this.LoaderSpinnerShow();
             if(this.id_historia_aiepi!==''){
                historia_aiepi.id=this.id_historia_aiepi;
                await historiaAiepiSeccionService.update(historia_aiepi);
            }else{
                await historiaAiepiSeccionService.store(historia_aiepi);
            }
           // await historiaAiepiSeccionService.store(historia_aiepi);
            this.LoaderSpinnerHide();
            Swal.fire('Datos guardados con exito','', 'success');
            $('#modalitemsSeccion').modal('hide');
            
        }
    },
    async created() {
        this.id_seccion=this.idSeccion;
        this.id_historia=this.idHistoria;
             
    }
}
</script>

